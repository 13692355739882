<template>
  <div>
    <input type="file" id="imageid" @change="handleFile" />
    <div id="canvas" style="display:none"></div>
  </div>
</template>

<script>
import convertPdf from "./convertpdf";
// import PDFJS from "pdfjs";

export default {
  data() {
    return {};
  },
  mounted() {
    // this.runConvert();
  },
  methods: {
    async handleFile(e) {
      let file = e.target.files[0];

      if (!file) {
        return;
      }

      let base64 = await this.imgToBase64(file);
      let arr = base64.split(",");
      let base64Data = arr[1];

      var PDFJS = window["pdfjs-dist/build/pdf"];

      PDFJS.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.js";

      var loadingTask = PDFJS.getDocument({ data: atob(base64Data) });
      console.log(loadingTask);

      loadingTask.promise.then(
        function(pdf) {
          console.log("aa", pdf);
          var canvasdiv = document.getElementById("canvas");
          var totalPages = pdf.numPages;
          var data = [];

          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            pdf.getPage(pageNumber).then(function(page) {
              var scale = 1.5;
              var viewport = page.getViewport({ scale: scale });

              var canvas = document.createElement("canvas");
              canvasdiv.appendChild(canvas);

              // Prepare canvas using PDF page dimensions
              var context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = { canvasContext: context, viewport: viewport };

              var renderTask = page.render(renderContext);

              renderTask.promise.then(function() {
                console.log(`page ${pageNumber}`, canvas.toDataURL("image/png"));

                // console.log(`page ${pageNumber}`, canvas.toDataURL("image/png"));

                // data.push(canvas.toDataURL("image/png"));
                // console.log(data.length + " page(s) loaded in data");
              });
            });
          }
        },
        function(reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    imgToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    runConvert() {
      var url = "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf";

      var PDFJS = window["pdfjs-dist/build/pdf"];

      PDFJS.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.js";

      var loadingTask = PDFJS.getDocument(url);

      loadingTask.promise.then(
        function(pdf) {
          console.log("aa", pdf);
          var canvasdiv = document.getElementById("canvas");
          var totalPages = pdf.numPages;
          var data = [];

          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            pdf.getPage(pageNumber).then(function(page) {
              var scale = 1.5;
              var viewport = page.getViewport({ scale: scale });

              var canvas = document.createElement("canvas");
              canvasdiv.appendChild(canvas);

              // Prepare canvas using PDF page dimensions
              var context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = { canvasContext: context, viewport: viewport };

              var renderTask = page.render(renderContext);
              renderTask.promise.then(function() {
                data.push(canvas.toDataURL("image/png"));
                console.log(data.length + " page(s) loaded in data");
              });
            });
          }
        },
        function(reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    submit(data) {
      this.showLoading = true;

      let url =
        "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCv22vemzG4kY1ujcNMYCD0YKQGgAo3t7E";

      this.$http
        .post(
          url,
          {
            requests: [
              {
                image: {
                  content: `${data}`
                },
                features: [
                  {
                    type: "TEXT_DETECTION"
                  }
                ]
              }
            ]
          },
          { disabledBaseUrl: true }
        )
        .then(res => {
          this.val = res;
        })
        .finally(() => {
          this.showLoading = false;
        });
    }
  }
};
</script>

<style>
canvas {
  border: 1px solid black;
  margin: 5px;
  width: 25%;
}
</style>
